import React from 'react';
import { SocialIcon } from 'react-social-icons';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styled from '@emotion/styled/macro'
import {Logo} from './Logo';
import {LoaderVideo} from './LoaderVideo';

const EmailFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 100%;
`;

const EmailInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  font-size: 20px;
  padding: 0 10px;
`;

const SignupButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: black;
  color: white;
  min-height: 40px;
  width: 100%;
  padding: 0px 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background: #333;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: darkred;
  padding: 0 5px;
`;

const SuccessMessage = styled(ErrorMessage)`
  color: darkgreen;
`;

const EmailForm = ({ status, message, onValidated }: any) => {
  const [email, setEmail] = React.useState('');

  console.log(status, message);

  return (
    <EmailFormWrapper>
      <EmailInput name="email" type="text" placeholder="enter your email" onChange={e => setEmail(e.target.value)} />
      {message && status === 'error' && <ErrorMessage>{message}</ErrorMessage>}
      {message && status === 'success' && <SuccessMessage>{message}</SuccessMessage>}
      <SignupButton disabled={!email || status === 'sending'} onClick={() => onValidated({ MERGE0: email })}>
        sign up to stay informed
      </SignupButton>
    </EmailFormWrapper>
  );
};

const u = "3a713578dbd637317e419c970";
const id = "20aab1679f";
const submitUrl = `https://scrmbl.us22.list-manage.com/subscribe/post?u=${u}&id=${id}`;

const SignupForm = () => {
  return (
    <MailchimpSubscribe
      url={submitUrl}
      render={({ subscribe, status, message }) => (
          <EmailForm
              status={status} 
              message={message}
              onValidated={(formData: any) => subscribe(formData)}
          />
      )}
    />
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: black;
`;

const Padder = styled.div`
  flex: 1;
  background: white;
`;

const TopPadder = styled(Padder)`
  min-height: 5vh;
  flex-shrink: 0;
`;
const BottomPadder = styled(Padder)`
  flex-shrink: 0;
  padding: 40px 20px 100px;
`;

const SocialSpace = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const FormSpace = styled.div`
  display: flex;
  justify-content: center;
`;

const MiddleContainer = styled.div`
  display: flex;
  height: 60vh;
  max-height: 90vw;
  flex-shrink: 0;
`;

const LogoContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  flex-shrink: 0;

  > svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100.5%;
    height: 100.5%;
  }
`;

const socials = [
  "bsky.app/profile/scrmbltokyo.bsky.social",
  "facebook.com/scrmbltokyo",
  "instagram.com/scrmbltokyo",
  "threads.net/@scrmbltokyo",
  "tiktok.com/@scrmbltokyo",
  "x.com/scrmbltokyo",
  "youtube.com/@scrmbltokyo",
];

function App() {
  return (
    <MainContainer>
      <TopPadder />
      <MiddleContainer>
        <Padder />
        <LogoContainer>
          <LoaderVideo url="" />
          <Logo />
        </LogoContainer>
        <Padder />
      </MiddleContainer>
      <BottomPadder>
        <SocialSpace>
          {socials.map(socialurl => (
            <SocialIcon
              bgColor='transparent'
              fgColor='black'
              url={`https://${socialurl}`}
              target="_blank"
            />
          ))}
        </SocialSpace>
        <FormSpace>
          <SignupForm />
        </FormSpace>
      </BottomPadder>
    </MainContainer>
  );
}

export default App;
