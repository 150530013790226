import React from 'react';
import ReactPlayer from 'react-player/lazy';
import styled from '@emotion/styled/macro'

const FadeInVideo = styled.div<{ loaded: boolean }>`
  position: absolute;
  overflow: hidden;
  height: 100%;
  /* Keep it the right aspect-ratio */
  aspect-ratio: 16/9;
  /* No clicking/hover effects */
  pointer-events: none;

  transition: all 1s;
  opacity: ${({ loaded }) => loaded ? 1 : 0};


  iframe {
    /* Extend it beyond the viewport... */
    width: 300%;
    height: 100%;
    /* ...and bring it back again (probably dont need to because we're gonna flex center it) */
    /* margin-left: -100%; */
    margin-left: -100%;
  }
`;

export const LoaderVideo = ({ url }: { url: string }) => {
  const [loaded, setLoaded] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);

  const onLoad = () => {
    setLoaded(true);
    console.log("loaded?");
  };

  const onPlay = () => {
    setPlaying(true);
    console.log("playing??");
  }

  return (
    <FadeInVideo loaded={playing}>
      <ReactPlayer
        muted
        loop
        playing={loaded}
        onPlay={onPlay}
        onReady={onLoad}
        url="https://www.youtube.com/watch?v=QOM_hXHDv6I"
        height="100%"
        width="100%"
      />
    </FadeInVideo>
  );
};
